.dashboard-list {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding-right: 15px;
  overflow-x: hidden;

  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 30px;
    justify-content: center;
    height: 100%;

    .icon {
      width: 64px;
      height: 64px;
      opacity: 0.4;
      color: var(--sl-white);
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      color: var(--sl-white);
    }

    .info,
    .link {
      font-weight: 400;
      font-size: 16px;
      color: var(--sl-off-white);
      text-align: center;
      line-height: 160%;
    }

    .link {
      cursor: default;
      opacity: 0.8;
    }
    .link:hover {
      opacity: 1;
    }
    .link:active {
      opacity: 0.6;
    }
  }

  .new-overlay {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;
    width: 170px;
    height: 48px;
    color: #ffffff;
    background: var(--sl-brand);
    border-radius: 56px;
    cursor: default;
  }
  .new-overlay:hover {
    background: var(--sl-brand-hover);
  }
  .new-overlay:active {
    background: var(--sl-brand-active);
  }

  .metadata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    position: relative;
    margin-right: auto;
    max-width: fit-content;
    padding-right: 10px;

    .name-group {
      display: flex;
      gap: 5px;
      align-items: center;
      height: 36px;
      width: calc(100% - 40px);
      min-width: 240px;
      cursor: text;
      flex-shrink: 0;

      .name {
        font-size: 16px;
        text-align: left;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--sl-white);
        max-width: 260px;
      }
      .name.busy {
        display: flex;
        font-size: 24px;
        height: 36px;
      }
      .icon {
        opacity: 0;
        flex-shrink: 0;
      }

      .input {
        position: relative;
        left: -16px;
        font-family: Nunito;
        background: transparent;
        border: 1px solid #886192;
        border-radius: 8px;
        padding: 0px 16px;
        height: 33px;
        font-size: 16px;
        color: var(--sl-white);
        font-weight: 400;
        width: 260px;
      }
    }
    .name-group:hover {
      .icon {
        opacity: 0.8 !important;
      }
    }
    .name-group:active {
      .icon {
        opacity: 0.5 !important;
      }
    }

    .changed {
      margin-top: 10px;
      color: var(--sl-white);
      opacity: 0.6;
      display: flex;
      align-items: center;
      gap: 5px;

      .text {
        line-height: 16px;
        font-weight: 400;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      background: transparent;
      border: none;
    }
    .button {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      .back {
        width: 100%;
        height: 100%;
        background: var(--sl-stroke);
        border-radius: 32px;
        opacity: 0.4;
        position: absolute;
      }
      .icon {
        width: 16px;
        height: 16px;
        color: var(--sl-white);
        opacity: 0.6;
      }
    }
    .label {
      font-family: Nunito;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: var(--sl-white);
      opacity: 0.6;
      cursor: default;
      padding-top: 12px;
      white-space: nowrap;
    }

    .menu-background {
      box-shadow: var(--sl-modal-shadow);
      border-radius: 8px;
      background: var(--sl-dropdown);
      width: 152px;
      padding: 0px;
    }

    .menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 12px;
      gap: 8px;
      width: 136px;
      height: 16px;
      border-bottom: 1px solid var(--sl-stroke);
      color: var(--sl-white);
      text-decoration: none;
      font-size: 12px;
      font-weight: 600;
      cursor: default;
    }
    .menu-item:hover {
      background: var(--sl-dropdown-hover);
    }
    .menu-item:active {
      background: var(--sl-dropdown-active);
    }
    .menu-item:nth-child(2) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .menu-item:last-child {
      border-bottom: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .menu:hover {
    .back {
      opacity: 0.6;
    }
    .label {
      opacity: 0.8;
    }
    .icon {
      opacity: 0.8;
    }
  }
  .menu:active {
    .back {
      opacity: 0.3;
    }
    .label {
      opacity: 0.5;
    }
    .icon {
      opacity: 0.5;
    }
  }

  .grid {
    display: grid;
    grid-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(min(max(300px, 24%), 100%), 1fr));

    .item {
      max-width: 500px;
      background: var(--sl-card);
      box-shadow: var(--sl-sharp-shadow);
      border-radius: 16px;
      position: relative;

      .item-busy {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0px;
        left: 0px;
        border-radius: 16px;
        background: var(--sl-card-busy);
        pointer-events: all;
      }

      .item-content {
        position: relative;

        .item-content-aspect {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;

          .center {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;

            .image {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 16px 16px 4px 4px;
            }
          }
        }
      }
      .item-content:after {
        content: "";
        display: block;
        padding-bottom: 56.25%;
        // 16:9 => padding-bottom: 56.25%;
        // 16:10 => padding-bottom: 62.5%;
        // 4:3 => padding-bottom: 75%;
        // 1:1 => padding-bottom: 100%;
      }

      .metadata {
        padding: 16px 24px;
        height: 120px;
        margin-right: inherit;
        max-width: inherit;

        .tags {
          margin-top: 14px;
        }

        &.small {
          height: 60px;
        }
      }

      .selection {
        position: absolute;
        left: 15px;
        top: 11px;
        opacity: 0;
        background: var(--sl-white-75);
        width: 24px;
        height: 24px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .selection.active {
        opacity: 1;
        svg {
          background: white;
        }
      }

      .favorite {
        position: absolute;
        right: 15px;
        top: 11px;
        opacity: 0;
        background: var(--sl-white-75);
        width: 25px;
        height: 24px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .favorite.active {
        opacity: 1;
        background: var(--sl-orange);
      }

      .menu {
        position: absolute;
        right: 12px;
        top: 18px;

        .button {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          border: 1px solid var(--sl-stroke);
          .back {
            background: transparent;
          }
        }
      }
    }
    .item:hover {
      background: var(--sl-card-hover);
      .selection {
        opacity: 1;
      }
      .favorite {
        opacity: 1;
      }
      .metadata {
        .name-group {
          .icon {
            opacity: 0.6;
          }
        }
      }
      .menu {
        .button {
          opacity: 1 !important;
        }
      }
    }
    .item.busy {
      .item-content,
      .metadata,
      .selection,
      .favorite {
        filter: blur(2px);
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      background: var(--sl-card);
      border-radius: 16px;
      box-shadow: var(--sl-list-shadow);
      position: relative;

      .item-busy {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0px;
        left: 0px;
        border-radius: 16px;
        background: var(--sl-card-busy);
        pointer-events: all;
      }
      .image {
        border-radius: 8px;
        width: 224px;
        height: 126px;
        margin: 8px;
        object-fit: contain;
      }

      .offset {
        flex: 1 0 1px;
        max-width: 20px;
      }
      .vertical-line {
        height: 68px;
        border-right: 1px dashed var(--sl-stroke);
      }

      .tags {
        margin-top: 14px;
      }

      .selection {
        position: absolute;
        left: 20px;
        top: 15px;
        opacity: 0;
        background: var(--sl-white-75);
        width: 24px;
        height: 24px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .selection.active {
        opacity: 1;
        svg {
          background: white;
        }
      }

      .favorite {
        position: absolute;
        left: 193px;
        top: 15px;
        opacity: 0;
        background: var(--sl-white-75);
        width: 25px;
        height: 24px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .favorite.active {
        opacity: 1;
        background: var(--sl-orange);
        color: var(--sl-white);
      }
      .menu {
        .button {
          opacity: 1 !important;
        }
      }
    }
    .item:hover {
      background: var(--sl-card-hover);
      .selection {
        opacity: 1;
      }
      .favorite {
        opacity: 1;
      }
    }
    .item.busy {
      .image,
      .metadata,
      .selection,
      .favorite,
      .menu {
        filter: blur(2px);
      }
    }
  }
}
