.modal-overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;

  .modal-dialog {
    // 100 is padding top and bottom of modal-overlay, 64 is padding top and bottom of modal-dialog
    height: fit-content;
    max-height: calc(100% - 100px - 64px);
  }
}

@media (max-height: 900px) {
  .modal-overlay {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .modal-dialog {
    max-height: calc(100% - 30px - 64px) !important;
    .content {
      gap: 10px !important;
    }
  }
}

@media (max-height: 800px) {
  .modal-overlay {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .modal-dialog {
    max-height: calc(100% - 10px - 64px) !important;
    .content {
      gap: 1px !important;
    }
  }
}

// this will blur the whole app when the background is open
body.ReactModal__Body--open {
  .toplevel {
    filter: blur(4px) grayscale(0.3);
  }
}

.modal-dialog {
  position: absolute;
  width: 320px;
  border-radius: 24px;
  padding: 32px;
  background-color: var(--sl-card);
  box-shadow: var(--sl-modal-shadow);

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;

    &.full-size {
      width: 100%;
      height: 100%;
    }
  }

  .login-header,
  .forgotpassword-header,
  .verifyemail-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    align-items: center;
    padding-bottom: 30px;

    .logo {
      //      width: 80px;
      height: 35px;
      margin-top: 12px;
    }

    .close {
      color: var(--sl-white);
      opacity: 0.8;
    }
    .close:hover {
      opacity: 1;
    }
    .close:active {
      opacity: 0.7;
    }
  }

  .sendfeedback-header,
  .deleteaccount-header,
  .librarydetails-header,
  .uploadimage-header,
  .stripecheckout-header {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;

    .close {
      color: var(--sl-white);
      opacity: 0.8;
      opacity: 0.8;
      flex: 1 1 0px;
      text-align: right;
    }
    .close:hover {
      opacity: 1;
    }
    .close:active {
      opacity: 0.7;
    }
  }

  .title {
    color: var(--sl-white);
    font-size: 24px;
    margin-block: unset;

    .sub-title {
      font-size: 12px;
      font-weight: 400;
      opacity: 0.6;
    }
  }

  .message-busy {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--sl-white);
    gap: 10px;
  }

  .message {
    color: var(--sl-white);
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    opacity: 0.8;

    .message-link {
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
      opacity: 0.8;
    }
    .message-link:hover {
      opacity: 1;
    }

    .message-highlight {
      color: var(--sl-action-red);
      font-weight: bold;
    }
  }

  .stripe-checkout {
    width: calc(100% - 20px);
    height: 1px;
    flex: 1;
    border-radius: 10px;
    background: #ffffff;
    padding: 10px;

    div {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .label-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      position: relative;

      .input {
        padding: 0px 16px;
        width: 288px;
        height: 39px;
        color: var(--sl-white);
        opacity: 0.6;
        font-size: 14px;
        background: var(--sl-stroke-transparent);
        border: 1px solid var(--sl-field);
        border-radius: 8px;
      }

      .input.textarea {
        width: 550px;
        height: 200px;
        padding: 16px 16px;
        resize: none;
      }

      .input.invalid {
        border: 1px solid var(--sl-action-red);
      }

      .error-message {
        color: var(--sl-action-red);
        font-size: 12px;
        position: absolute;
        top: 70px;
        left: 17px;
        display: flex;
        flex-direction: row;
        gap: 6px;
      }

      .password-reveal {
        position: absolute;
        right: 23px;
        top: 37px;
        color: var(--sl-white);
        display: flex;
        opacity: 0.6;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
      }
      .password-reveal:hover {
        opacity: 0.8;
      }
      .password-reveal:active {
        opacity: 0.5;
      }

      .password-strength {
        height: 4px;
        border-bottom-left-radius: 5px;
        position: relative;
        top: -9px;
        left: 1px;
        margin-bottom: -9px;
        transition:
          width 0.3s,
          background-color 0.3s;
      }

      .password-strength.level-off {
        width: 0%;
        background-color: transparent;
      }

      .password-strength.level-0 {
        width: 25%;
        background-color: red;
      }

      .password-strength.level-1 {
        width: 50%;
        background-color: orange;
      }
      .password-strength.level-2 {
        width: 75%;
        background-color: blue;
      }
      .password-strength.level-3 {
        width: calc(100% - 2px);
        background-color: green;
        border-bottom-right-radius: 5px;
      }

      .password-strength-label {
        position: absolute;
        right: 15px;
        bottom: -15px;
        color: var(--sl-white);
        font-size: 10px;
        text-align: right;
        opacity: 0.6;
      }

      .label {
        opacity: 0.8;
        font-size: 14px;
        color: var(--sl-white);
        font-weight: 600;
      }

      .offset {
        height: 10px;
      }
    }

    .password-group {
      display: flex;
      flex-direction: column;

      a {
        font-weight: 600;
      }
    }

    .link {
      margin-top: 10px;
      margin-left: auto;
      color: var(--sl-off-white);
      text-decoration: none;
      cursor: pointer;
      font-size: 14px;
      text-align: right;
      height: 22px;
      opacity: 0.8;
    }
    .link:hover {
      opacity: 1;
    }
    .link:active {
      opacity: 0.7;
    }
  }

  .login-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .button-brand {
    width: 320px;
    height: 48px;
    color: #ffffff;
    background: var(--sl-brand);
    padding: 13px 18px 13px 16px;
    border-radius: 56px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.04em;
    border: 0px;
  }
  .button-brand:hover {
    background: var(--sl-brand-hover);
  }
  .button-brand:active {
    background: var(--sl-brand-active);
  }
  .button-brand:disabled {
    background: var(--sl-brand-disabled);
  }
  .button-brand.login-button {
    width: 220px;
  }

  .button {
    width: 200px;
    height: 48px;
    font-size: 14px;
    border-radius: 56px;
    color: var(--sl-white);
    background: transparent;
    margin-left: auto;
    padding: 11px 24px;
    border: 1px solid var(--sl-white);
    font-family: Nunito;
    font-weight: 700;
    letter-spacing: 0.04em;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .button:hover {
    opacity: 1;
  }
  .button:active {
    opacity: 0.6;
  }
  .button:disabled {
    opacity: 0.5;
  }
  .button.danger {
    color: var(--sl-action-red);
    border: 1px solid var(--sl-action-red);
  }
  .busy.danger {
    color: var(--sl-action-red);
  }

  .mode-toggle {
    color: var(--sl-yellow);
    text-decoration: none;
    cursor: pointer;
    opacity: 0.8;
  }
  .mode-toggle:hover {
    opacity: 1;
  }
  .mode-toggle:active {
    opacity: 0.7;
  }

  .sign-up {
    font-size: 16px;
    color: var(--sl-off-white);
    text-decoration: none;
    width: 320px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-weight: 400;
  }

  .signup-error {
    font-weight: 600;
    font-size: 14px;
    color: var(--sl-action-red);
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;

    .icon {
      width: 27px;
      height: 19px;
    }
  }
}

.modal-dialog.sendfeedback,
.modal-dialog.deleteaccount,
.modal-dialog.singularinfo {
  width: 584px;
}

.modal-dialog.stripecheckout {
  width: 500px;
  min-height: 300px;
  height: 100%;
}

.modal-dialog.uploadimage {
  width: 800px;
}
