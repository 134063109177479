.banner {
  display: flex;
  right: 20px;
  top: 29px;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateY(calc(-100% - 35px));
  box-shadow: var(--sl-modal-shadow);
  background: var(--sl-brand);
  border-radius: 24px;
  padding: 16px;
  transition: transform 0.3s ease-in-out;

  .icon {
    height: 30px;
    width: 20px;
    padding: 5px;
    padding-right: 15px;
  }

  .content {
    flex: 1 1 auto;
    height: 100%;
    max-width: 80%;
    min-width: 600px;
    font-family: Nunito;
    color: #fff;

    .line {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
    }
    .subtitle {
      font-size: 12px;
      font-weight: 400;
    }

    .button {
      width: 100px;
      padding: 10px;
      border: 1px solid #ffffff;
      border-radius: 56px;
      background-color: #ffffffc0;
      color: #1f1a30;
    }
    .button:hover {
      background-color: #ffffffff;
    }
    .button:active {
      background-color: #ffffffa0;
    }
  }
  .close {
    color: var(--sl-white);
    opacity: 0.8;
    opacity: 0.8;
    padding-left: 20px;
  }
  .close:hover {
    opacity: 1;
  }
  .close:active {
    opacity: 0.7;
  }
}

.banner.visible {
  transform: translateY(0%);
}
