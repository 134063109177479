.feature-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  width: calc(100% - 40px);
  padding: 20px;
  max-width: 900px;

  &-header {
    font-size: 32px;
    color: var(--sl-white);
  }

  &-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // to enable unoPro and unoElite
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 0px;
    width: 100%;
    margin-top: 40px;

    &-header-cell {
      font-size: 24px;
      font-weight: 700;
      color: var(--sl-white);
      padding: 8px 0px;
      border-bottom: 2px solid var(--sl-stroke-transparent);
      text-align: left;

      .soon,
      .start {
        font-size: 14px;
        font-weight: 600;
        margin: 10px 0px 5px 0px;
        opacity: 0.8;
      }
      .start {
        color: var(--sl-yellow);
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        &:active {
          opacity: 0.6;
        }
      }

      .line {
        display: flex;
        gap: 15px;
        align-items: center;
      }

      .badge {
        height: 16px;
        background: var(--sl-yellow);
        color: var(--sl-dark);
        font-size: 12px;
        padding: 2px 7px;
        border-radius: 16px;
        font-weight: 700;
      }
    }
    &-cell {
      display: flex;
      height: 60px;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      border-bottom: 2px solid var(--sl-stroke-transparent);

      &.first {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
