.folder {
  margin-top: 5px;
  cursor: default;
  display: flex;
  height: 16px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  gap: 10px;
  align-items: center;

  .name {
    opacity: 0.9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .icon {
    opacity: 0.8;
  }
}

.folder.enabled {
  .name:hover {
    opacity: 1;
  }
  .name:active {
    opacity: 0.6;
  }

  .icon:hover {
    opacity: 1;
  }
  .icon:active {
    opacity: 0.6;
  }
}
