.header {
  height: 48px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;

  .search-input {
    flex: 1 0 200px;
    position: relative;
    max-width: 560px;

    .input {
      width: calc(100% - 88px);
      height: 24px;
      padding: 11px 32px;
      background: var(--sl-stroke-transparent);
      border: 1px solid var(--sl-stroke);
      border-radius: 56px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: var(--sl-white);
      padding-right: 54px;
    }
    .icon {
      position: absolute;
      right: 32px;
      top: 17px;
      opacity: 0.6;
    }
    .icon-reset {
      position: absolute;
      right: 28px;
      top: 9px;
      opacity: 0.6;
      padding: 5px;
    }
    .icon-reset:hover {
      opacity: 0.8;
    }
    .icon-reset:active {
      opacity: 0.5;
    }

    &.hidden {
      display: none;
    }
  }

  .offset {
    flex: 1 0 15px;
  }

  .right-area {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    flex: 1 0 150px;
    padding-right: 7px;
    gap: 15px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .feedback {
      width: 100%;
      gap: 12px;
      border-radius: 56px;
      border: 1px solid var(--sl-white);
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.04em;
      color: var(--sl-white);
      height: 38px;
      background: transparent;
      cursor: default;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
      }
      &:active {
        background: rgba(0, 0, 0, 0.56);
      }
    }

    .login,
    .signup {
      gap: 8px;
      width: 100%;
      min-width: 100px;
      height: 48px;
      background: var(--sl-brand);
      color: #ffffff;
      border-radius: 56px;
      cursor: default;

      &:hover {
        background: var(--sl-brand-hover);
      }
      &:active {
        background: var(--sl-brand-active);
      }
    }
  }
}

.header.shift-right {
  padding-left: 226px;
  width: calc(100% - 226px);
}
