.modal-dialog.librarydetails {
  width: 75%;
  height: 100% !important;
  max-width: 704px;
  display: flex;
}

.librarydetails {
  .arrow {
    cursor: default;
    width: 48px;
    height: 48px;
    border: 1px solid #ffffff33;
    border-radius: 24px;
    color: var(--sl-white);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 32px);
    // background: var(--sl-stroke-transparent);
    background: var(--sl-background);
    opacity: 0.8;
  }
  .arrow.left {
    left: -75px;
  }
  .arrow.right {
    right: -75px;
  }

  .arrow:hover {
    opacity: 1;
  }
  .arrow:active {
    opacity: 0.6;
  }
  .arrow.hidden {
    display: none;
  }

  .content {
    flex: 1 1 1px;
    max-width: 800px;
    border-radius: 10px;
    display: flex;
    position: relative;
    gap: 0px !important;

    .new {
      padding: 3px 4px;
      gap: 4px;
      width: 28px;
      background: var(--sl-yellow);
      border-radius: 16px;
      font-weight: 800;
      font-size: 8px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .visual {
      position: relative;
      width: 100%;
      aspect-ratio: 16 / 9;
      margin-top: 25px;
      margin-bottom: 10px;

      .video {
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 16 / 9;
        min-width: 100%;
      }
      .image {
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 16 / 9;
        min-width: 100%;
        &.overlay {
          position: absolute;
          top: 0px;
          left: 0px;
        }
      }
      .videoLoading {
        position: absolute;
        display: flex;
        top: 0px;
        width: 100%;
        aspect-ratio: 16 / 9;
        justify-content: center;
        align-items: center;
        color: var(--sl-black);
      }
    }

    .ql-editor {
      min-height: 60px;
      width: 100%;
      padding: 0px;
      font-size: 14px;
      font-weight: 400;
      color: var(--sl-off-white);
      overflow-x: hidden;
      overflow-y: scroll;
      margin-bottom: 15px;
      flex-grow: 1;
      margin-block: 0px;
    }
    .ql-editor::-webkit-scrollbar-thumb {
      background-color: var(--sl-modal);
    }

    .description {
      display: flex;
      width: 100%;
      flex: 1 1 1px;
      height: 1px;
      margin-block: 0px;
      color: var(--sl-white);

      .description-selection {
        width: 220px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 1em;
        padding-right: 20px;

        .description-selection-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          cursor: default;
          flex-shrink: 0;
          opacity: var(--sl-opacity-default);

          &:hover {
            opacity: var(--sl-opacity-hover);
          }
          &:active {
            opacity: var(--sl-opacity-active);
          }

          .description-selection-item-icon {
            fill: var(--sl-white);
            flex-shrink: 0;
            display: flex;
          }

          .description-selection-item-title {
            position: relative;
            top: 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
          }
        }
        .description-selection-item.selected {
          .description-selection-item-icon {
            opacity: 1;
          }
          .description-selection-item-title {
            opacity: 1;
            color: var(--sl-yellow);
          }
        }
      }

      .description-display {
        position: relative;
        display: flex;
        width: 100%;

        .variants {
          min-height: 60px;
          font-size: 14px;
          font-weight: 400;
          overflow-x: hidden;
          overflow-y: scroll;
          flex-grow: 1;
          margin-top: 1em;

          // I tried grid, but just displaying block looks better
          // display: grid;
          // grid-template-columns: minmax(max-content, 1fr) 3fr;
          // align-items: center;
          // grid-column-gap: 20px;
          display: block;

          .variant-title {
            font-size: 16px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .variant-resolution {
            color: var(--sl-white-75);
          }

          .variant-description {
            color: var(--sl-white-75);
            margin-bottom: 20px;
          }
        }
        .variants.mobile {
          display: block;
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 15px;
      padding-top: 20px;
      background-color: var(--sl-card);
      z-index: 1;
      
      .button-brand {
        width: 200px;
      }
    }
  }
}

.library-detail.hidden {
  opacity: 0;
  pointer-events: none;
}

@media (max-height: 900px) {
  .modal-dialog.librarydetails {
    padding: 16px;
    height: 660px;

    .visual {
      margin-top: 10px;
    }
  }
}
@media (max-height: 800px) {
  .modal-dialog.librarydetails {
    padding: 16px;
    height: 520px;

    .visual {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
    }
  }
}

@media (max-height: 600px) {
  .modal-dialog.librarydetails {
    padding: 16px;
    height: 370px;

    .visual {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }
}

@media (max-height: 500px) {
  .modal-dialog.librarydetails {
    height: 100% !important;
    max-height: 100% !important;

    .visual {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      width: 35%;
    }
  }
}
