.library-filter {
  width: 100%;
  height: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;

  .filter-header {
    display: flex;
    font-weight: 600;
    font-size: 20px;
    color: var(--sl-white);
    justify-content: space-between;
    margin-bottom: 24px;

    .reset {
      font-weight: 600;
      font-size: 12px;
      color: var(--sl-yellow);
      letter-spacing: 0.04em;
      display: flex;
      align-items: center;
      opacity: 0.8;
    }
    .reset:hover {
      opacity: 1;
    }
    .reset:active {
      opacity: 0.6;
    }
  }

  .content {
    flex: 1 1;
    overflow-y: scroll;
    gap: 16px;
    display: flex;
    flex-direction: column;
    cursor: default;
    margin-right: -2px;
  }

  .filter-section {
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px 0px;
    gap: 8px;
    border-top: 1px dashed var(--sl-stroke);

    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      gap: 8px;
      color: var(--sl-white);

      .label {
        font-weight: 600;
        font-size: 14px;
        opacity: 0.8;
      }

      .icon {
        width: 16px;
        height: 16px;
        opacity: 0.8;
      }

      .clear {
        font-weight: 600;
        font-size: 12px;
        color: var(--sl-yellow);
        letter-spacing: 0.04em;
        opacity: 0.8;
      }
      .clear:hover {
        opacity: 1;
      }
      .clear:active {
        opacity: 0.6;
      }

      .show {
        margin-left: auto;
        opacity: 0.8;
      }
    }
    .title:hover {
      .label,
      .icon,
      .show {
        opacity: 1;
      }
    }
    .title:active {
      .label,
      .icon,
      .show {
        opacity: 0.6;
      }
    }

    .tag {
      display: flex;
      flex-direction: row;
      gap: 8px;
      font-weight: 400;
      font-size: 12px;
      opacity: 0.8;
    }
    .tag:hover {
      opacity: 1;
    }
    .tag:active {
      opacity: 0.6;
    }
    .tag.selected {
      opacity: 1;
    }

    .show-more {
      display: flex;
      font-weight: 600;
      font-size: 12px;
      gap: 2px;
      color: var(--sl-yellow);
      cursor: pointer;
      opacity: 0.8;
      .icon {
        fill: var(--sl-yellow);
      }
    }
    .show-more:hover {
      opacity: 1;
    }
    .show-more:active {
      opacity: 0.6;
    }
  }
}
