.home-library-item {
  background: var(--sl-card);
  box-shadow: var(--sl-sharp-shadow);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: calc(100% - 20px);

  .item-content {
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
    
    .marker {
      z-index: 1;
      position: absolute;
      width: 20%;
      top: 5%;
      left: 2%;
    }
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 16px 16px 4px 4px;
    }
    .video {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 16px 16px 4px 4px;
    }
  }

  .metadata {
    padding: 16px 24px;
    height: 76px;
    width: calc(100% - 48px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    position: relative;

    .name-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      width: 100%;

      .name {
        font-size: 16px;
        text-align: left;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--sl-white);
        max-width: 100%;
      }
      .new {
        padding: 3px 4px;
        gap: 4px;
        width: 28px;
        background: var(--sl-yellow);
        border-radius: 16px;
        font-weight: 800;
        font-size: 8px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.home-library-item.small {
  .metadata {
    padding: 8px 12px;
    height: 70px;
    width: calc(100% - 24px);

    .name-group {
      .name {
        font-size: 14px;
      }
      .new {
        padding: 2px 3px;
        gap: 4px;
        width: 22px;
        font-size: 7px;
      }
    }
  }
}

.home-library-item:hover {
  background: var(--sl-card-hover);
}
