.main {
  display: flex;
  width: 100%;
  height: calc(100% - var(--sl-page-footer-height));
  position: relative;

  .right-side {
    display: flex;
    flex-direction: column;
    width: calc(100% - 64px);
    height: calc(100% - 42px);
    position: relative;
    padding: 32px;
    padding-bottom: 10px;
  }
}
