.subscription {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% + 15px);

  &-header {
    font-size: 20px;
    color: var(--sl-white);
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      width: 160px;
      font-weight: 700;
      font-size: 16px;
      padding: 8px 0px;
      color: var(--sl-white);
      opacity: 0.8;
    }
  }

  .plans {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 20px;
    width: calc(100% - 40px);
    max-width: 900px;

    &-singular-header {
      font-size: 32px;
      color: var(--sl-white);
      margin-top: 60px;
    }

    &-singular-logo {
      width: 250px;
      margin-top: 30px;

      img {
        width: 100%;
      }
    }

    &-singular-tagline {
      font-size: 20px;
      font-weight: 400;
      color: var(--sl-white);
      margin-top: 30px;
      font-weight: 500;
      max-width: 500px;
      text-align: center;
    }
    &-singular-nav-item {
      font-weight: 700;
      color: var(--sl-white);
      text-decoration: none;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.5;
      }
    }

    &-header {
      font-size: 32px;
      color: var(--sl-white);
      padding-top: 10px;
    }

    &-change-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      cursor: default;
      font-size: 24px;
      color: var(--sl-white);
      margin-right: auto;

      .icon {
        width: 32px;
        height: 32px;
        opacity: 0.8;
      }

      &:hover {
        .icon {
          opacity: 1;
        }
      }
      &:active {
        .icon {
          opacity: 0.6;
        }
      }
    }

    &-tagline {
      font-size: 20px;
      font-weight: 400;
      color: var(--sl-white);
      margin-top: 12px;
    }

    &-period {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      margin-top: 30px;
      margin-bottom: 20px;
      padding-left: 3px;
      padding-right: 3px;
      height: 48px;
      border-radius: 56px;
      cursor: default;
      border: 1px solid var(--sl-white);
      color: var(--sl-white);

      &-button {
        height: 34px;
        border-radius: 56px;
        display: flex;
        align-items: center;
        padding-left: 18px;
        padding-right: 18px;

        &.monthly {
          margin-left: 5px;
        }

        &.yearly {
          margin-right: 5px;
          display: flex;
          align-items: center;
          gap: 5px;
        }

        &.save {
          font-size: 12px;
          color: var(--sl-white);
          background: var(--sl-action-red) !important;
          border-radius: 30px;
          padding: 1px 10px;
          height: 24px;
        }

        &.selected {
          background: var(--sl-brand);
          border: 0px;
          color: var(--sl-white);
          .save {
            background: var(--sl-white) !important;
            color: var(--sl-action-red);
          }
          &:hover {
            background: var(--sl-brand);
          }
        }

        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      // use this when we enable uno pro
      // justify-content: space-between;
      justify-content: space-evenly;
      width: 100%;
      gap: 40px 3%;
      margin-top: 40px;

      .plan {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 200px;
        max-width: 320px;
        align-items: flex-start;
        gap: 25px;
        padding: 20px;
        background: var(--sl-dark);
        border: 2px solid var(--sl-stroke-transparent);
        border-radius: 24px;

        &.full-width {
          max-width: 100%;
          flex: 1 1;
          gap: 30px;

          .plan {
            border: none;
            background: transparent;
          }

          .vertical {
            display: flex;
          }

          .plan.left-side {
            max-width: none;
          }

          .plan.right-side {
            flex: 0;
            min-width: 250px;
            justify-content: center;
          }
        }

        &.current {
          border: 2px solid var(--sl-yellow-50);
        }

        &-title {
          display: flex;
          align-items: center;
          gap: 15px;
          font-size: 32px;
          color: var(--sl-white);

          svg {
            width: 32px;
            height: 32px;
            position: relative;
            top: -3px;
            flex-shrink: 0;
          }
        }

        &-badge {
          background: var(--sl-yellow);
          color: var(--sl-dark);
          font-size: 12px;
          padding: 2px 7px;
          border-radius: 16px;
          font-weight: 700;
        }

        &-description {
          font-size: 16px;
          font-weight: 400;
          color: var(--sl-white);
          opacity: 0.8;
        }

        &-footer {
          display: flex;
          padding-top: 10px;
          gap: 10px;
          padding: 0px 20px;
        }

        &-bullets {
          display: flex;
          flex-direction: column;
          gap: 20px;
          opacity: 0.8;

          &-bullet {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;
            font-size: 16px;
            font-weight: 400;
            color: var(--sl-white);

            svg {
              padding: 3px 4px;
              flex-shrink: 0;
            }
            svg.filled {
              padding: 0px;
              position: relative;
              top: -1px;
            }
          }
        }

        &-offset {
          flex: 1;
        }

        &-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 0px auto;
          width: 170px;
          height: 48px;
          color: #ffffff;
          border-radius: 56px;
          cursor: default;
          border: 1px solid var(--sl-white);
          color: var(--sl-white);

          &.hidden {
            visibility: hidden;
          }
          &.promo {
            background: var(--sl-brand);
            border: 0px;
            &:hover {
              background: var(--sl-brand-hover);
            }
          }

          &:hover {
            background: rgba(255, 255, 255, 0.08);
          }
        }

        &-current-plan {
          height: 48px;
          font-size: 20px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0px auto;
          color: var(--sl-white);

          &.promo {
            color: var(--sl-yellow);
          }
          .no-renew {
            color: var(--sl-white);
            font-size: 14px;
            opacity: 0.8;
          }
        }

        &-price {
          display: flex;
          flex-direction: column;
          margin: 0px auto;

          .discount {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            margin: 10px 0px;
            flex-wrap: wrap;
            justify-content: center;

            &-price {
              position: relative;

              .number {
                font-size: 16px;
                opacity: 0.8;
                color: var(--sl-white);
              }
              &:after {
                position: absolute;
                content: "";
                left: -10%;
                top: 50%;
                right: 0;
                width: 120%;
                border-top: 2px solid var(--sl-action-red);
                transform: rotate(-5deg);
              }
            }

            &-info {
              font-size: 12px;
              font-weight: 400;
              color: var(--sl-white);
              opacity: 0.8;
            }

            &-save {
              font-size: 12px;
              font-weight: 400;
              color: var(--sl-white);
              background: var(--sl-action-red);
              border-radius: 30px;
              padding: 6px 10px;
            }
          }

          .payment {
            font-size: 16px;
            font-weight: 500;
            color: var(--sl-white);
            &-price {
              font-size: 32px;
              font-weight: 700;
              color: var(--sl-white);
              margin: 0px auto;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            &-date {
              font-size: 12px;
              font-weight: 400;
              color: var(--sl-white);
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .checkout-dialog {
    background: var(--sl-white);
    color: var(--sl-dark);
    max-width: 600px;
    width: 50%;
    height: calc(100% - 20px);
    border-radius: 10px;
    padding: 10px;

    div {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }

  .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--sl-yellow);
    text-decoration: none;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.5;
    }
  }
}
