.tags {
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 0 0 24px;
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  
  .tag {
    display: flex;
    height: 16px;
    flex-shrink: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--sl-white);
    padding: 4px 8px;
    background: var(--sl-stroke);
    border-radius: 8px;
    opacity: 0.8;
    gap: 5px;
    align-items: center;
  }
}

.tags.can-click {
  .tag:hover {
    opacity: 1;
  }
  .tag:active {
    opacity: 0.6;
  }
}
.tags.small {
  gap: 6px;
  .tag {
    height: 16px;
    font-size: 10px;
    padding: 2px 6px;
    gap: 3px;

    svg {
      width: 13px;
      height: 13px;
    }
  }
}
