.subscription-status {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-x: hidden;
  overflow-y: scroll;

  &-header {
    font-size: 24px;
    font-weight: 700;
    color: var(--sl-white);
    padding-top: 3px;
    padding-bottom: 50px;
  }

  .status-line {
    min-height: 50px;
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--sl-white);
    margin-left: 24px;

    .title {
      width: 270px;
      font-weight: 700;
      font-size: 16px;
      opacity: 0.8;
    }

    .text {
      font-size: 16px;
      font-weight: 400;
      opacity: 0.8;
    }
  }

  .info {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
    margin-top: 10px;
    margin-left: 24px;
  }

  .button-line {
    display: flex;
    border-top: 1px dashed var(--sl-stroke);
    width: max-content;
    min-width: 400px;
    min-height: 90px;
    gap: 16px;
    cursor: default;
    padding-left: 230px;
    margin-left: 24px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 46px;
    cursor: pointer;
    border: 1px solid var(--sl-white);
    color: var(--sl-white);
    border-radius: 56px;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.04em;
    margin-top: 40px;

    &.delete {
      border: 1px solid var(--sl-action-red);
      color: var(--sl-action-red);
    }
    &.promo {
      background: var(--sl-brand);
      border: 0px;
      &:hover {
        background: var(--sl-brand-hover);
      }
      &.disabled:hover {
        background: var(--sl-brand);
      }
    }
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 0.6;
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
      cursor: default;
      opacity: 0.5;
    }
  }

  .busy.small {
    width: 12px;
    height: 12px;
  }

  .busy.delete {
    color: var(--sl-action-red);
  }

  .call-to-action {
    max-width: 1000px;
    min-height: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--sl-stroke);
    border-radius: 24px;
    align-items: center;
    gap: 40px;
    color: var(--sl-white);
    padding: 20px 30px;
    margin-top: 10px;

    &-title {
      font-size: 32px;
      font-weight: 700;
      min-width: 250px;
    }

    .text {
      flex: 1 1 320px;
      font-size: 16px;
      font-weight: 400;
      opacity: 0.8;
    }

    .button {
      margin-top: 0px;
      cursor: default;
    }
  }
}
