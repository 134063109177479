.upload-image-item {
  display: flex;
  gap: 15px;
  align-items: center;
  background: var(--sl-stroke-transparent);
  border: 1px solid var(--sl-stroke);
  border-radius: 24px;

  .left {
    padding-left: 10px;

    .icon {
      width: 120px;
      height: 120px;
      object-fit: contain;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 30px;
        height: 30px;
        color: white;
      }
    }
  }

  .right {
    padding-right: 10px;
    color: var(--sl-white);
    flex-grow: 1;

    .line {
      display: flex;
      width: 100%;
      align-items: center;
      font-weight: 400;

      .name {
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 275px;
      }

      .close {
        margin-left: auto;
        opacity: 0.8;
      }
      .close:hover {
        opacity: 1;
      }
      .close:active {
        opacity: 0.6;
      }
    }

    .progress {
      width: 100%;
      background: var(--sl-stroke-transparent);
      border-radius: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .bar {
        height: 10px;
        background: var(--sl-orange);
        width: 0%;
        transition: width 0.3s;
      }
      .bar.complete {
        background: var(--sl-action-green);
      }
    }
    .progress-info,
    .progress-error-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 14px;
    }

    .progress-error {
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 275px;
    }
  }
}
